import { VBtn } from 'vuetify/lib/components/VBtn';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"column":""}},[_c(VList,{staticClass:"width:100%",attrs:{"name":"Types","dense":""}},_vm._l((_vm.$store.getters['CompetitonsModule/filterByType'](_vm.typeId)),function(item){return _c(VListGroup,{key:item.id,attrs:{"value":false,"no-action":"","sub-group":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VListItemAction,{staticClass:"pr-0 align-center"},[(item.loading)?_c(VBtn,{attrs:{"icon":"","loading":""}}):_vm._e(),_c('tool-tip-icon',{attrs:{"icon":"mdi-information","message":_vm.$t('inactive'),"visible":item.disabled,"color":"red"}})],1)]},proxy:true}],null,true)},[_c('groups-chips',{attrs:{"competition":item}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }