
import ToolTipIcon from './ToolTipIcon.component.vue'
import GroupsChips from './Groups.component.vue'
// @group Components
export default {
  name: 'Competitions',
  components: {
    GroupsChips,
    ToolTipIcon
  },
  props: {
    // The id of type
    typeId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      list: {}
    }
  },
  watch: {
    typeId: function (newVal: string, oldVal: string) {
      this.$store.dispatch('CompetitonsModule/fetchAndAdd', { where: [['typeId', '==', this.typeId]] })
    }
  },
  mounted () {
    this.$store.dispatch('CompetitonsModule/fetchAndAdd', { where: [['typeId', '==', this.typeId]] })
  }
}
