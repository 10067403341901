
import Vue from 'vue'
import { Group } from '@/interfaces/group'
import ToolTipIcon from './ToolTipIcon.component.vue'
export default Vue.extend({
  name: 'GroupsChips',
  components: {
    ToolTipIcon
  },
  props: {
    competition: Object
  },
  data () {
    return {
      list: [] as Group[]
    }
  },
  mounted () {
    // eslint-disable-next-line
    this.$store.dispatch('GroupModule/fetchAndAdd', { where: [['competitionId', '==', this.competition.id]] })
      .then(() => {
        this.list = this.$store.getters['GroupModule/filterByCompetition'](this.competition.id)
      })
  }
})
