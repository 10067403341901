
/* eslint-disable */
import firebase from 'firebase/compat/app'
import moment from 'moment'

export default {
  name: 'CreateSeason',
  props: {
    display: Boolean
  },
  data () {
    return {
      state: false,
      e6: 1,
      start: false,
      end: false,
      season: {
        id: null,
        name: null,
        start: null,
        end: null,
        competitionTypeInputs: [
          {
            id: 'type0',
            order: 0,
            name: '',
            typeId: ''
          }
        ]
      },
      file: ''
    }
  },
  computed: {
    dialog: {
      get () {
        return this.display
      },
      set (val: any) {
        this.$emit('closeSeasonDialog', val)
        this.$emit('closed')
      }
    }
  },
  watch: {
    pickerStart (val: any) {
      val && setTimeout(() => (this.$refs.pickerStart.activePicker = 'YEAR'))
    },
    pickerEnd (val: any) {
      val && setTimeout(() => (this.$refs.pickerEnd.activePicker = 'YEAR'))
    }
  },
  methods: {
    saveStart (date: string) {
      this.$refs.seasonstart.save(date)
    },
    saveEnd (date: any) {
      this.$refs.seasonend.save(date)
    },
    addRow () {
      this.$data.season.competitionTypeInputs.push({
        id: `player${this.$data.season.competitionTypeInputs.length + 1}`,
        name: '',
        order: this.$data.season.competitionTypeInputs.length,
        typeId: ''
      })
    },
    completeStepOne () {
      const start = moment(this.season.start)
      const end = moment(this.season.end)
      this.season.name = start.format('YYYY') + '/' + end.format('YY')
      if (start.isSame(end, 'y')) {
        this.season.name = start.format('YYYY')
      }
      this.e6 = 2
    },
    completeStepTwo () {
      if (this.season.name === null) {
        alert(this.$t('create-season-set-start-and-finish'))
        return
      }
      this.e6 = 3
    },
    async completeStepThree () {
      const season = {
        id: null,
        name: this.season.name,
        start: firebase.firestore.Timestamp.fromDate(new Date(this.season.start)),
        end: firebase.firestore.Timestamp.fromDate(new Date(this.season.end))
      }
      season.id = await this.$store.dispatch('SeasonModule/insert', season)
      const types = []
      this.$data.season.competitionTypeInputs.forEach((element: { name: any }, i: any) => {
        types.push({
          name: element.name,
          order: i,
          seasonId: season.id
        })
      })
      this.$store.dispatch('CompetitionTypesModule/insertBatch', types).then(() => {
        this.dialog = false
        this.$emit('seasonCreated', season)
      })
    }
  }
}
