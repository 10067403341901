
import Vue from 'vue'
import moment from 'moment'
import Shared from '../../shared.js'

import CompetitionComponent from '../../components/Competitons.component.vue'
import NewSeasonComponent from '../../components/CreateSeason.component.vue'
import { showSnackbar } from '@/globalActions/index.js'

export default Vue.extend({
  name: 'Competitions',
  components: {
    CompetitionComponent,
    NewSeasonComponent
  },
  mixins: [Shared],
  data () {
    return {
      season: false,
      dialog: false,
      expanded: [],
      singleExpand: false,
      dialogData: {
        info: {},
        matches: []
      },
      divisionHeaders: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: false,
          value: 'name'
        }
      ],
      items: {},
      seasons: Array<any>(),
      selected: { name: '', id: '' },
      loading: false,
      competitionType: {
        name: ''
      },
      competitionTypeAlert: false
    }
  },
  watch: {
    selected: function (val) {
      this.changeSeason(val.id)
    }
  },
  created () {
    this.$store.dispatch('venues/fetchAndAdd', { clauses: { limit: 0 } })
    this.$store.dispatch('SeasonModule/fetchAndAdd').then(() => {
      this.seasons = this.$store.getters['SeasonModule/sortedASC']
      this.selected = this.seasons[this.seasons.length - 1]
    })
  },
  methods: {
    changeSeason (val: string) {
      this.$store.dispatch('CompetitionTypesModule/fetchAndAdd', { where: [['seasonId', '==', val]] })
        .then(() => {
          this.$store.dispatch('CompetitionTypesModule/filterBySeason', { season: val })
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    closeDialog () {
      this.dialog = false
      this.dialogData = {
        info: {},
        matches: []
      }
    },
    onTypeCreate (e: any) {
      e.preventDefault()
      if (this.competitionType.name === '') {
        showSnackbar(this.$t('please-enter-name'), { color: 'red' })
        return
      }
      this.competitionTypeAlert = false
      this.loading = true
      this.$store.dispatch('CompetitionTypesModule/set', {
        name: this.competitionType.name,
        seasonId: this.selected.id
      }).then(() => {
        this.competitionType.name = ''
        this.changeSeason(this.selected.id)
      })
    },
    onSeasonCreated (value: { data: any; display: boolean }) {
      this.$store.dispatch('SeasonModule/fetchAndAdd')
      this.seasons.push(value.data)
      this.season = value.display
    },
    closeSeasonDialog () {
      this.season = false
    },
    parseDate (date: any) {
      return moment(moment.unix(date.seconds), 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  }
})
